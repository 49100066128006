import { useMemo, useRef, useState } from 'react';
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { sortBy } from 'lodash';

import OrganisationForm from './OrganisationForm';
import OrganisationAddForm from './OrganisationAddForm';

import EntityAvatar from '../../components/EntityAvatar';
import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';
import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';
import RookieButton from '../../components/RookieButton';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { ERROR_TYPES } from '../../types/common';
import { Mixpanel } from '../../util/mixpanel';
import usePermission from '../../hooks/usePermission';

const OrganisationListingView = (props) => {
    const { isError, isFetching, isLoading, onGenerateCode } = props;
    const navigate = useNavigate();

    const organisationCodeToast = useRef(null);
    const formResponseToast = useRef();

    const { associationID } = useParams();

    const [searchParams, setSearchParams] = useSearchParams('');

    const { isAdmin } = usePermission(associationID);

    const openCreationPortal = () => {
        if (!searchParams.get('form')) {
            setSearchParams((params) => {
                params.set('form', 'create');
                return params;
            });
            Mixpanel.track('Open Organisation Create');
        }
    };
    const [addAnOrg, setAddAnOrg] = useState(false); // Default to false
    const openAddAnOrgPortal = () => {
        if (!searchParams.get('form')) {
            setSearchParams((params) => {
                params.set('form', 'add');
                return params;
            });
            setAddAnOrg(true);
            Mixpanel.track('Open Organisation Add');
        }
    };

    const closeCreationPortal = () => {
        if (searchParams.get('form')) {
            setSearchParams((params) => {
                params.delete('form');
                return params;
            });
            setAddAnOrg(false);
        }
    };

    const handleSubmitResult = (submitResult) => {
        const hasSubmitted = submitResult === 'success';
        const hasUpdated = submitResult === 'updated';

        formResponseToast.current?.show({
            severity: hasSubmitted || hasUpdated ? 'success' : 'warn',
            summary: hasUpdated
                ? `Organisation Updated`
                : hasSubmitted
                ? `Organisation Added`
                : `Organisation Addition Unsuccessful`,
            detail: hasUpdated
                ? `The Organisation details have been amended.`
                : hasSubmitted
                ? `The new Organisation has been successfully added.`
                : `Organisation creation could not be completed, try again later.`,
        });
    };

    const handleCodeClick = (code) => {
        // Copy the code
        navigator.clipboard.writeText(code);

        // Show toast
        organisationCodeToast.current.show({
            summary: 'Organisation code copied',
            detail: code,
        });
    };

    const columns = useMemo(() => {
        const nameTemplate = (data) => {
            const route = associationID
                ? `/a/${associationID}/o/${data.organisationID}`
                : `/o/${data.organisationID}`;

            return (
                <Link to={route} className="list-item link-item">
                    <EntityAvatar
                        entityID={data.organisationID}
                        entityName={data.organisationName}
                        entityType="organisations"
                    />
                    {data.organisationName}
                </Link>
            );
        };

        const joinCodeTemplate = (data, row) => {
            return data.organisationJoinMode !== 'Disabled' ? (
                <SplitButton
                    model={[
                        {
                            command: () => onGenerateCode('Disabled', data),
                            label: 'Disable code',
                        },
                    ]}
                    icon={<Icon name="content_copy" />}
                    label={data.organisationJoinCode}
                    onClick={() => handleCodeClick(data.organisationJoinCode)}
                    className="p-button-rounded p-button-secondary"
                />
            ) : (
                <SplitButton
                    label="Generate Code"
                    model={[
                        {
                            command: () => onGenerateCode('SingleUse', data),
                            label: 'Single Use',
                        },
                    ]}
                    className="p-button-secondary p-button-rounded"
                    onClick={() => onGenerateCode('Enabled', data)}
                >
                    Generate
                </SplitButton>
            );
        };

        return [
            {
                body: isLoading ? <Skeleton /> : nameTemplate,
                field: 'organisationName',
                header: 'Name',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : null,
                field: 'age',
                header: 'Age',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : null,
                field: 'gender',
                header: 'Gender',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : null,
                field: 'grade',
                header: 'Grade',
                sortable: true,
            },
            {
                body: isLoading ? (
                    <Skeleton />
                ) : (
                    (data) => format(parseISO(data.lastEdited), 'dd/MM/yyyy')
                ),
                field: 'lastEdited',
                header: 'Last Edited',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : joinCodeTemplate,
                field: 'organisationJoinCode',
                header: 'Join Code',
                sortable: false,
            },
        ];
    }, [associationID, isLoading, onGenerateCode]);

    const mappedColumns = columns.map((col, i) => (
        <Column key={`col-${i}`} {...col} />
    ));

    const headerActions = [
        ...(isAdmin
            ? [
                  {
                      command: props.onImportClick,
                      key: 'import',
                      label: 'Import Organisations',
                      severity: 'secondary',
                  },
                  {
                      command: () => openAddAnOrgPortal(),
                      icon: 'add',
                      key: 'add',
                      label: 'Link Organisation',
                  },
                  {
                      command: () => openCreationPortal(),
                      icon: 'add',
                      key: 'create-new',
                      label: 'Create New',
                  },
              ]
            : []),
    ];

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                          {
                              command: () => navigate(0), // refresh
                              icon: 'refresh',
                              label: 'Retry',
                          },
                      ]
                    : headerActions
            }
            alignment="middle"
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : 'Check in with Rookie Me to receive oversight of your organisations.'
            }
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No Organisations found`}
        />
    );

    // empty array to populate rows for skeleton loading components
    const blankRows = Array.from({ length: 5 });

    const footerTemplate = () => {
        return (
            <RookieButton
                onClick={props.onLoadMore}
                severity="secondary"
                label="Load more"
                icon="pending"
            />
        );
    };

    return (
        <PageContainer>
            <PageHeader title="Organisations" actions={headerActions} />
            <DataTable
                value={
                    isLoading
                        ? blankRows
                        : sortBy(props.data, 'organisationName')
                }
                loading={isFetching && !isLoading}
                footer={
                    !isFetching && props.showPagination ? footerTemplate : null
                }
                emptyMessage={tableEmptyMessage}
                columnResizeMode="expand"
                resizableColumns
            >
                {isLoading
                    ? mappedColumns
                    : props.data.length > 0
                    ? mappedColumns
                    : null}
            </DataTable>

            <Sidebar
                header={addAnOrg ? 'Add Organisation' : 'Create Organisation'}
                onHide={() => {
                    closeCreationPortal();
                }}
                visible={searchParams.has('form')}
                position="right"
            >
                {addAnOrg ? (
                    <OrganisationAddForm
                        onSubmit={handleSubmitResult}
                        onCancel={() => closeCreationPortal()}
                    />
                ) : (
                    <OrganisationForm
                        onSubmit={handleSubmitResult}
                        onCancel={() => closeCreationPortal()}
                    />
                )}
            </Sidebar>

            <Toast ref={formResponseToast} />
            <Toast ref={organisationCodeToast} />
        </PageContainer>
    );
};

export default OrganisationListingView;
